<template>
    <div>
        <b-breadcrumb class="breadcrumpsCustom">
            <b-breadcrumb-item href="/admin/users">
                <!-- <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon> -->
                <feather-icon class="breadcrumpsCustomIcon" icon="HomeIcon" scale="1.25" shift-v="1.25"
                    aria-hidden="true" />
                Users
            </b-breadcrumb-item>
            <b-breadcrumb-item active>Group Permission</b-breadcrumb-item>
        </b-breadcrumb>
        <b-card no-body class="mb-0">

            <div class="m-2">
                <b-row>
                    <b-col>
                        <b-container>
                            <div id='permissionsTable'>
                                <b-row class='headerRow'>
                                    <b-col cols='3'>Permissions</b-col>
                                    <b-col v-for="role in roles" v-bind:key="role.name">{{role.name}}</b-col>
                                </b-row>
                                <!-- v-bind:name="role.name + 'Permissions'"  -->
                                <b-row v-for="permission in permissions" v-bind:key="permission.name" class="bodyRow">
                                    <b-col cols='3'>{{permission.name}}</b-col>
                                    <b-col v-for="(role, index) in roles" v-bind:key="role.name">
                                        <b-form-checkbox-group v-bind:id="role.name" v-model="roles[index].permission">
                                            <b-form-checkbox v-bind:value="permission.id"
                                                @change.native="permissionUpdate($event,roles[index])" />
                                        </b-form-checkbox-group>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-container>
                    </b-col>
                </b-row>
            </div>
        </b-card>




    </div>
</template>

<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, BBreadcrumb, BIcon, BBreadcrumbItem, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BContainer, BFormCheckboxGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue';

export default {
    components: {
        ToastificationContent,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BBreadcrumb,
        BIcon,
        BBreadcrumbItem,
        BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BContainer, BFormCheckboxGroup,
        vSelect,
    },
    mounted() {
        this.fetchGroups();
    },
    data() {
        return {
            permissions: [{
                id: "read",
                name: "Read"
            },
            {
                id: "write",
                name: "Write"
            },
            {
                id: "create",
                name: "Create"
            },
            {
                id: "delete",
                name: "Delete"
            }
            ],

            roles: [],
            permissionObj: [
                {
                    'name': 'read',
                    'status': false
                },
                {
                    'name': 'write',
                    'status': false
                },
                {
                    'name': 'create',
                    'status': false
                },
                {
                    'name': 'delete',
                    'status': false
                },
            ]

        }
    },
    methods: {
        async fetchGroups() {
            const res = await this.$axios.get(`${this.$API_URL_GROUPS}/fetchGroups`);
            let group = await res;
            let resp = group.data.response

            resp.forEach((element, key) => {
                if (element.access) {
                    resp[key].permission = [];
                    if (element.access.create) {
                        resp[key].permission.push('create')
                    }

                    if (element.access.read) {
                        resp[key].permission.push('read')
                    }

                    if (element.access.write) {
                        resp[key].permission.push('write')
                    }

                    if (element.access.delete) {
                        resp[key].permission.push('delete')
                    }
                } else {
                    resp[key].permission = [];
                }

            });
            this.roles = resp;
        },

        async permissionUpdate($event, model) {
            let checked = $event.target.checked;
            let value = $event.target.value;


            let objIndex = this.permissionObj.findIndex((obj => obj.name == value));
            this.permissionObj[objIndex].status = checked

            let permissionUpdate = await this.$axios.post(`${this.$API_URL_GROUPS}/permissionUpdate`, {
                data: model,
                permission: this.permissionObj
            });

            if (permissionUpdate.data.success) {
                Vue.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                        title: `Success`,
                        icon: "CoffeeIcon",
                        variant: "success",
                        text: `Permission updated!`,
                    },
                });
            } else {
                Vue.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'EditIcon',
                        variant: 'danger',
                        text: 'Something went wrong'
                    },
                })
            }
        },

    }
}
</script>

<style>
.headerRow {
    padding: .75rem;
    background-color: #000000;
    color: #ffffff;
    font-weight: bold;
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.bodyRow {
    padding: .75rem;
    border-top: 1px solid #dee2e6;
}
</style>